<template>
  <div>
    <nav class="breadcrumb is-right" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a v-if="organisation">{{ organisation.name }}</a>
        </li>
        <li class="is-active">
          <a v-if="branch">{{ branch.name }}</a>
        </li>
        <li class="is-active">
          <a v-if="user">{{ user.name }}</a>
        </li>
      </ul>
    </nav>
    <p class="subtitle is-4" v-if="title">{{ title }}</p>
    <div class="tabs" :class="{ 'is-fullwidth': $mq === 'mobile' }">
      <ul>
        <li
          :class="{
            'is-active has-text-weight-semibold':
              $route.name === 'account-activity-user'
          }"
        >
          <router-link
            :to="{ name: 'account-activity-user' }"
            @click.native="track('activity_user')"
            >My activity</router-link
          >
        </li>
        <li
          :class="{
            'is-active has-text-weight-semibold':
              $route.name === 'account-activity-branch'
          }"
        >
          <router-link
            :to="{ name: 'account-activity-branch' }"
            @click.native="track('activity_branch')"
            >Branch activity</router-link
          >
        </li>
        <li
          v-if="organisation && branchHasAdmin && isBranchAdmin"
          :class="{
            'is-active has-text-weight-semibold':
              $route.name === 'account-activity-org'
          }"
        >
          <router-link :to="{ name: 'account-activity-org' }"
            >Organisation activity</router-link
          >
        </li>
        <li
          v-if="branchHasAdmin && isBranchAdmin"
          :class="{
            'is-active has-text-weight-semibold':
              $route.name === 'account-branch-admin'
          }"
        >
          <router-link
            :to="{ name: 'account-branch-admin' }"
            @click.native="track('branch_admin')"
            >Manage branches</router-link
          >
        </li>
        <li
          :class="{
            'is-active has-text-weight-semibold':
              $route.name === 'account-details'
          }"
        >
          <router-link
            :to="{ name: 'account-details' }"
            @click.native="track('profile')"
            >Edit profile</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AccountBreadcrumbRoute',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      branch: 'auth/branch',
      isBranchAdmin: 'auth/isBranchAdmin',
      branchHasAdmin: 'auth/branchHasAdmin',
      organisation: 'auth/organisation'
    })
  },
  methods: {
    track(source) {
      this.$mxp.track(`account_click_${source}_tab`)
    }
  }
}
</script>
