var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"breadcrumb is-right",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',{staticClass:"is-active"},[(_vm.organisation)?_c('a',[_vm._v(_vm._s(_vm.organisation.name))]):_vm._e()]),_c('li',{staticClass:"is-active"},[(_vm.branch)?_c('a',[_vm._v(_vm._s(_vm.branch.name))]):_vm._e()]),_c('li',{staticClass:"is-active"},[(_vm.user)?_c('a',[_vm._v(_vm._s(_vm.user.name))]):_vm._e()])])]),(_vm.title)?_c('p',{staticClass:"subtitle is-4"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',{staticClass:"tabs",class:{ 'is-fullwidth': _vm.$mq === 'mobile' }},[_c('ul',[_c('li',{class:{
          'is-active has-text-weight-semibold':
            _vm.$route.name === 'account-activity-user'
        }},[_c('router-link',{attrs:{"to":{ name: 'account-activity-user' }},nativeOn:{"click":function($event){return _vm.track('activity_user')}}},[_vm._v("My activity")])],1),_c('li',{class:{
          'is-active has-text-weight-semibold':
            _vm.$route.name === 'account-activity-branch'
        }},[_c('router-link',{attrs:{"to":{ name: 'account-activity-branch' }},nativeOn:{"click":function($event){return _vm.track('activity_branch')}}},[_vm._v("Branch activity")])],1),(_vm.organisation && _vm.branchHasAdmin && _vm.isBranchAdmin)?_c('li',{class:{
          'is-active has-text-weight-semibold':
            _vm.$route.name === 'account-activity-org'
        }},[_c('router-link',{attrs:{"to":{ name: 'account-activity-org' }}},[_vm._v("Organisation activity")])],1):_vm._e(),(_vm.branchHasAdmin && _vm.isBranchAdmin)?_c('li',{class:{
          'is-active has-text-weight-semibold':
            _vm.$route.name === 'account-branch-admin'
        }},[_c('router-link',{attrs:{"to":{ name: 'account-branch-admin' }},nativeOn:{"click":function($event){return _vm.track('branch_admin')}}},[_vm._v("Manage branches")])],1):_vm._e(),_c('li',{class:{
          'is-active has-text-weight-semibold':
            _vm.$route.name === 'account-details'
        }},[_c('router-link',{attrs:{"to":{ name: 'account-details' }},nativeOn:{"click":function($event){return _vm.track('profile')}}},[_vm._v("Edit profile")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }